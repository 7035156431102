:root {
  --side-nav-dark-blue: #1f2f3e;
  --side-nav-border-color: #304050;
  --primary-font: "Public Sans", sans-serif;
  --primary-color: #e7c16d;
  --dark-yellow-color: #d2ae5f;
  --secondary-color: #7a8c9b;
  --black-color: #000000;
  --black-shade-1: #031c2d;
  --extra-light-yellow: #fffbf4;
  --light-yellow: #f6f0e5;
  --medium-yellow: #fbf3e7;
  --green-color: #92b893;
  --border-color: #ece1cc;
  --light-cream: #fffaf3;
  --light-yellow: #f6f1e4;
  --light-gray: #505050;
  --gray-border: #c8c8c8;
  --tocbrand-light-silver: #d9d8d6;
  --tocbrand-light-slate: #798c9b;
  --increase-value: #1fa49a;
  --decrease-value: #ef4563;
  --btn-border: #9194b6;
  --btn-hover: #8a93e3;
  --table-td-bg: #f8f8ff;
  --review-star: #f8c629;
  --dark-gray: #151515;
}

::selection {
  background-color: var(--secondary-color);
  color: var(--bs-white);
}

p {
  color: rgba(0, 0, 0, 0.5);
}

body {
  font-family: var(--primary-font);
}

.sidebar-wrapper {
  width: 220px;
  transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.sidebar-wrapper.close_icon {
  width: 70px;
  z-index: 9;
}

.sidebar-wrapper.close_icon:hover {
  width: 220px;
  z-index: 9999;
}

.sidebar-wrapper.close_icon:hover .sidebar-link .menu-name {
  display: block !important;
  min-width: calc(100% - 35px) !important;
  transition: opacity 0.5s ease-in-out 0.25s;
  opacity: 1;
  transform: scale(1);
}

.btn-theme .spinner-border {
  color: white !important;
  width: 17.768px;
  height: 17.768px;
}

.sidebar-wrapper.close_icon:hover .sidebar-list .sidebar-link .menu-icon {
  width: 35px;
}

.page-body-wrapper .sidebar-wrapper.close_icon:hover ~ .page-body,
.page-wrapper .sidebar-wrapper.close_icon:hover ~ .page-body {
  margin-left: 220px;
}

.sidebar-wrapper.close_icon .logo-wrapper p {
  display: none;
}

.sidebar-wrapper.close_icon .sidebar-menu-list {
  height: calc(100vh - 74px);
}

.sidebar-wrapper.close_icon .sidebar-list .sidebar-link .menu-icon {
  width: 100%;
}

.sidebar-wrapper.close_icon .sidebar-link .menu-name {
  display: none !important;
  opacity: 0;
  transform: scale(0);
  width: 0;
}

.logo-wrapper img {
  height: 42px;
}

.logo-wrapper {
  background-color: var(--side-nav-dark-blue);
}

.sidebar-list {
  border-top: 1px solid var(--side-nav-border-color);
  background-color: var(--side-nav-dark-blue);
  position: relative;
}

.sidebar-list .sidebar-link .menu-icon {
  font-size: 20px;
  width: 35px;
  margin: 0 auto;
  transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
}

.sidebar-list .sidebar-link .menu-name {
  width: calc(100% - 35px);
  padding: 0 15px;
  white-space: nowrap;
  text-align: left;
  transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
  position: relative;
}

.sidebar-list .sidebar-link .menu-name svg {
  width: 13px;
  position: absolute;
  right: 5px;
  top: 0;
  transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
}

.menu-icon img {
  width: 22px;
}

.menu-text {
  font-size: 12px;
}

.sidebar-menu-list {
  height: calc(100vh - 74px);
  background-color: var(--side-nav-dark-blue);
}

.sidebar-menu-list .sidebar-submenu {
  overflow: auto;
  width: 100%;
  background-color: #283b4d;
  transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
  display: none;
  z-index: -1;
  animation: fadeInLeft 500ms ease-in-out 0s;
}

.sidebar-menu-list .sidebar-list a:not(.active) ~ .sidebar-submenu {
  -webkit-animation: fadeOutLeft 500ms ease-in;
  animation: fadeOutLeft 500ms ease-in;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateY(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translateY(-100%, 0, 0);
  }
}

.sidebar-head {
  color: #1f2f3e;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-left: 30px;
  margin-bottom: 12px;
  line-height: 16px;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  display: none;
}

.sidebar-menu-list .main-submenu a {
  text-decoration: none;
  padding: 12px 8px 12px 8px;
}

.sidebar-menu-list .main-submenu a span {
  width: 35px;
  text-align: center;
  color: var(--bs-white);
  opacity: 0.75;
}

.sidebar-menu-list .main-submenu a:hover,
.sidebar-menu-list .main-submenu a:focus {
  background-color: rgba(255, 255, 255, 0.05);
}

.sidebar-menu-list .main-submenu a:hover span {
  opacity: 1;
}

.sidebar-menu-list .main-submenu a .submenu-name {
  width: 100%;
  padding-left: 25px;
  text-align: left;
  font-size: 12px;
  text-transform: uppercase;
}

.sidebar-menu-list .main-submenu a span img {
  filter: invert(0%) sepia(98%) saturate(0%) hue-rotate(12deg) brightness(60%)
    contrast(101%);
}

.sidebar-menu-list .main-submenu a span svg {
  color: var(--bs-white) !important;
}

.sidebar-list .sidebar-link {
  padding: 18px 0;
  transition: all 400ms ease-in-out 0s;
  border-radius: 0;
}

.sidebar-list .sidebar-link:hover,
.sidebar-list .sidebar-link:focus {
  background-color: rgba(234, 234, 234, 0.2);
}

.sidebar-list .sidebar-link.active {
  background-color: var(--secondary-color);
}

.sidebar-link svg {
  margin: 0 auto;
  width: 22px;
  height: 22px;
  vertical-align: middle;
  stroke: var(--white);
}

.page-header {
  width: calc(100% - 220px);
  padding: 18px 40px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  z-index: 999;
}

.page-header.close_icon {
  width: calc(100% - 70px);
}

.page-header .left-header {
  cursor: pointer;
  left: -53px;
  z-index: 11;
}

.page-header .toggle-sidebar a {
  font-size: 28px;
  line-height: 1;
  background-color: rgb(204, 204, 204);
  border-radius: 0 25px 25px 0;
  color: var(--side-nav-dark-blue);
}

.page-header.close_icon .toggle-sidebar a {
  transform: rotateY(180deg);
  border-radius: 25px 0 0 25px;
}

.page-header .toggle-sidebar a svg {
  width: 20px;
}

.header-search {
  max-width: 300px;
}

.page-wrapper .sidebar-wrapper.close_icon ~ .page-body {
  margin-left: 67px;
}

.header-search input[type="text"] {
  font-size: 15px;
  padding: 7.75px 15px;
}

.atmosphere-hr .form-control,
.bootstrap-tagsinput {
  background-color: transparent;
  color: var(--side-nav-dark-blue);
  font-size: 15px;
  padding-right: 50px;
  padding: 7.75px 60px 7.75px 15px;
}

.atmosphere-hr .form-control:focus {
  box-shadow: none;
  outline: 0;
}

.atmosphere-logged-user {
  padding-left: 32px;
}

.atmosphere-logged-user .atmosphere-account {
  text-decoration: none;
  color: var(--dark-gray);
  font-weight: var(--semibold-font);
}

.atmosphere-logged-user .profile-image span {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
}

.atmosphere-logged-user .profile-image span img {
  width: 100%;
  min-height: 38px;
}

.atmosphere-logged-user .atmosphere-username {
  padding-left: 12px;
  text-align: left;
}

.atmosphere-logged-user .atmosphere-account::after {
  content: "";
  height: 9px;
  font-size: 0;
  width: 16px;
  background-image: url("../images/down-arrow.svg");
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center;
  margin-left: 12px;
}

.atmosphere-logged-user .dropdown-menu {
  transform: none !important;
  top: calc(100% + 5px) !important;
  border: 0;
  border-radius: 10px;
  background-color: var(--side-nav-dark-blue);
  min-width: 180px;
  padding: 5px 0 15px;
  left: initial !important;
  right: 0 !important;
}

.atmosphere-logged-user .welcome-user {
  padding: 15px 20px;
  border-bottom: 1px rgba(255, 255, 255, 0.12) solid;
  margin: 0 0 5px 0;
}

.atmosphere-logged-user .dropdown-nav {
  padding: 0 8px;
}

.atmosphere-logged-user .dropdown-item {
  color: var(--bs-white);
  padding: 10px 18px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-size: 15px;
}

.atmosphere-logged-user .dropdown-item .user-icon {
  margin-right: 10px;
  width: 22px;
}

.atmosphere-logged-user .dropdown-item:hover,
.atmosphere-logged-user .dropdown-item:focus {
  background-color: rgba(255, 255, 255, 0.05);
}

/* Page Content */
.page-body-wrapper {
  background-color: #f7f7f7;
  margin-top: 76px;
}

.page-body {
  padding: 45px 30px 25px 30px;
  margin-left: 220px;
  min-height: calc(100vh - 76px);
  transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.page-body-wrapper .sidebar-wrapper.close_icon ~ .page-body {
  margin-left: 67px;
}

.page-body-wrapper
  .sidebar-wrapper.close_icon
  .sidebar-menu-list
  .sidebar-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 220px;
  border-radius: 0 5px 5px 0;
}

.page-body .card {
  -webkit-box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  border-color: rgba(0, 0, 0, 0.07);
}

.page-body .card-header {
  border-color: rgba(0, 0, 0, 0.05);
}

.contact-icon {
  width: 45px;
}

.contact-icon span {
  width: 100%;
  height: 45px;
  background-color: var(--secondary-color);
}

.contact-text {
  width: calc(100% - 45px);
  padding-left: 20px;
  line-height: 1.65;
}

.contact-text a {
  display: block;
}

.page-body .card-body {
  padding: 25px 20px;
}

.contact-now a,
.contact-now i {
  color: var(--secondary-color);
}

.menu-cards {
  --auto-grid-min-size: 12rem;
  gap: 20px;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
}

.icon-nav {
  color: var(--secondary-color);
  font-size: 30px;
}

.card-title h6 {
  font-size: 18px;
}

.page-heading small {
  font-size: 16px;
  opacity: 0.5;
  margin: 5px 0 0;
}

.btn {
  font-size: 14px;
  font-weight: 600;
}
.btn,
.btn.btn-theme,
.btn.btn-theme.newuseradd-btn:visited,
.btn.btn-theme:disabled {
  background-color: var(--black-shade-1);
  border-color: var(--black-shade-1);
  font-size: 14px !important;
  font-weight: 600 !important;
}

.btn.btn-theme:hover,
.btn.btn-theme:focus,
.btn.btn-theme:visited,
.btn.btn-theme:active,
.btn.btn-theme:focus-within,
.btn.btn-theme:focus-visible,
.btn.btn-theme.newuseradd-btn:visited:hover,
.btn.btn-theme.newuseradd-btn:hover {
  background-color: var(--tocbrand-light-slate);
  border-color: var(--tocbrand-light-slate);
}

.login-fields .btn.btn-theme:hover,
.login-fields .btn.btn-theme:focus {
  color: var(--bs-white);
}

.btn.outline-btn {
  background-color: transparent;
  color: var(--secondary-color);
}

.btn.outline-btn:hover,
.btn.outline-btn:focus {
  background-color: var(--secondary-color);
  color: var(--bs-white);
  border-color: var(--secondary-color);
}

.list-type {
  width: 50%;
}

.type-view {
  width: 50px;
}

.type-view span {
  width: 100%;
  height: 50px;
  background-color: var(--secondary-color);
  font-size: 16px;
}

.list-type .type {
  width: calc(100% - 50px);
}

.list-mid {
  width: calc(50% - 100px);
  padding: 0 25px;
}

.field-lock-unlock {
  width: 100px;
  text-align: right;
}

.list-fileds li {
  padding: 25px;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  font-size: 18px;
}

.list-fileds li:not(:last-child) {
  margin-bottom: 15px;
}

.add-more-field .btn {
  font-size: 16px;
}

.left-nav {
  width: calc(100% - 100px);
}

.dashboards-nav-list li:not(:last-child) {
  margin-bottom: 20px;
}

.left-nav .card-title {
  width: calc(100% - 235px);
  padding: 0 35px 0 20px;
}

.dashboards-nav-list .icon-nav {
  width: 95px;
  height: 95px;
  border-radius: 10px;
  background-color: var(--secondary-color);
  color: var(--bs-white);
  font-size: 38px;
}

.close_icon
  ~ .page-body-wrapper
  .sidebar-wrapper.close_icon.sidebar-default
  ~ .page-body {
  margin-left: 332px;
}

.image-nav {
  width: 235px;
}

.tabledata .table tr th {
  background-color: #1f2f3e;
  color: var(--bs-white);
  font-weight: 600;
}

.tabledata .table tr th,
.tabledata .table tr td {
  font-size: 15px;
  padding: 15px 9px;
  vertical-align: middle;
}

.tabledata .table tr td a:not(.btn) {
  text-decoration: none;
  color: var(--secondary-color);
}

.tabledata .table tr td a:hover:not(.btn) {
  text-decoration: underline;
}

.tabledata .table tr th:not(:last-child) {
  border-right: 1px rgba(255, 255, 255, 0.35) solid;
}

.tabledata .table tr td {
  border-right: 1px rgba(0, 0, 0, 0.08) solid;
}

.tabledata .table tr td:first-child {
  border-left: 2px rgba(0, 0, 0, 0.08) solid;
}

.tabledata .table tr:nth-child(even) td {
  background-color: rgba(0, 0, 0, 0.02);
}

.tabledata .table tr[role="row"] td:last-child {
  white-space: nowrap;
}

.tabledata .table tr td .btn-theme:first-child {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.tabledata .table tr td .btn-theme:first-child:hover,
.tabledata .table tr td .btn-theme:first-child:focus {
  background-color: var(--black-shade-1) !important;
  border-color: var(--black-shade-1);
}

.upload-file-wrapper label input[type="file"],
.upload-assets-by input[type="file"] {
  left: -99999px;
}

.page-body-wrapper
  .sidebar-wrapper.close_icon:hover
  .sidebar-menu-list
  .sidebar-submenu,
.page-wrapper
  .sidebar-wrapper.close_icon:hover
  .sidebar-menu-list
  .sidebar-submenu {
  position: relative;
  z-index: 1;
  left: 0;
}

.upload-file-wrapper .upload-label,
.upload-file-wrapper .uploaded-img-view {
  border: 1px rgba(0, 0, 0, 0.28) dashed;
}

.upload-file-wrapper .uploaded-img-view > div {
  position: relative;
  width: 200px;
  height: 100%;
}

.upload-file-wrapper .uploaded-img-view {
  width: 100% !important;
  height: 153px;
  border-radius: var(--bs-border-radius-lg);
  padding: 20px 0;
}

.upload-icon {
  width: 70px;
  height: 70px;
  background-color: rgba(0, 0, 0, 0.08);
}

.drag-drop p {
  font-style: italic;
  font-size: 18px;
}

.review-settings .review-icon {
  width: 50px;
  height: 50px;
  background-color: var(--secondary-color);
  font-size: 20px;
}

.review-text {
  width: calc(100% - 50px);
  padding-left: 20px;
}

.review-settings li {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px rgba(0, 0, 0, 0.08) solid;
}

.review-settings li:last-child {
  border-bottom: 0;
}

.upload-assets-by {
  border: 1px rgba(0, 64, 64, 0.78) solid;
  overflow: hidden;
}

.upload-assets-by label {
  padding: 12px 35px;
}

.upload-assets-by label:first-child {
  background-color: var(--secondary-color);
  color: var(--bs-white);
}

.gallery-assets ul {
  gap: 24px;
}

.gallery-assets li {
  width: calc(20% - 19.2px);
}

.album-select {
  height: 23px;
}

.album-select input[type="checkbox"] {
  position: absolute;
  left: -99999px;
  top: 0;
}

.check-album {
  width: 23px;
  height: 23px;
  border: 1px rgba(0, 0, 0, 0.3) solid;
  border-radius: 3px;
  position: relative;
}

.album-select input:checked + .check-album {
  border-color: var(--secondary-color);
}

.check-album::before {
  content: "";
  position: absolute;
  left: 3px;
  top: 3px;
  height: 12px;
  width: 12px;
  z-index: 1;
}

.album-select input:checked + .check-album::before {
  background-color: var(--secondary-color);
}

.album-set-icon {
  font-size: 35px;
}

.album-set-icon img {
  width: 100%;
  object-fit: cover;
}

.album-title {
  background-color: rgba(0, 0, 0, 0.75);
}

.multiselect-box .ms-options-wrap {
  min-width: 220px;
}

.multiselect-box .ms-options-wrap > button::after {
  right: 12px;
  height: 8px;
  top: 16px;
  width: 14px;
  background-size: 12px;
}

.multiselect-box .ms-options-wrap > button,
.multiselect-box .ms-options-wrap.ms-has-selections > button,
.multiselect-box .ms-options-wrap > button:focus,
.multiselect-box .ms-options-wrap > button .multiselect-box button {
  padding: 7.75px 15px;
  font-size: 15px;
}

.atmosphere-hr .form-label {
  min-height: auto;
}

.atmosphere-hr .filter-btn {
  border: 1px #dee2e6 solid;
}

.filter-album .dropdown-menu {
  left: initial !important;
  min-width: 230px;
}

.top-album-info li {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.uploaded-album-info .ms-options-wrap > button,
.uploaded-album-info .ms-options-wrap.ms-has-selections > button,
.uploaded-album-info .ms-options-wrap > button:focus,
.uploaded-album-info .ms-options-wrap > button .uploaded-album-info button {
  padding: 16.8px 25px;
}

.property-img img {
  max-width: 50px;
}

.atmos-tabs .nav-item .nav-link {
  color: var(--black-color);
  font-size: 15px;
  border-radius: 5px;
  font-weight: 600;
  padding: 12px 20px;
}

.atmos-tabs .nav-item .nav-link.active {
  background-color: var(--secondary-color);
  color: var(--bs-white);
}

.facility-icon i {
  font-size: 20px;
}

.facility-icon {
  width: 60px;
  justify-content: center;
}

.addmore-facility {
  margin-top: 33px;
}

.highlights-extras li {
  margin-bottom: 12px;
  list-style-type: disc;
  list-style-position: inside;
}

.upload-file-wrapper .view-all-videos .upload-label {
  padding-top: 125px !important;
}

.view-all-videos .upload-icon {
  top: 70px;
}

.popup-assets-view li {
  width: calc(33.3333% - 19.2px);
}

.left-tabs .nav {
  background-color: #e0f3f3;
}

.left-tabs .nav-item {
  position: relative;
}

.left-tabs .nav-item .drop-drag-btn {
  left: 8px;
}

.left-tabs .nav .nav-link {
  padding: 17px 75px 17px 7px;
  color: rgba(0, 0, 0, 0.6);
  word-break: break-all;
}

.left-tabs .nav .nav-link.active {
  color: var(--secondary-color);
  background-color: rgba(0, 0, 0, 0.025);
}

.left-tabs .nav .nav-item:not(:last-child) {
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
}

.left-tabs .nav .nav-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
}

.left-tabs .nav .nav-item.active::before {
  background-color: var(--secondary-color);
}

.drop-drag-btn {
  padding-left: 15px;
}

.filter-buttons .list-view-button,
.filter-buttons .grid-view-button {
  padding: 7px 15px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

table tr td div svg {
  vertical-align: top;
  top: -2px;
  position: relative;
  margin-right: 20px;
  opacity: 0.35;
}

.status-btn {
  background-color: rgba(0, 0, 0, 0.7);
}

.status-btn.unpublish-btn {
  background-color: rgba(209, 146, 10, 0.7);
}

.status-btn:hover,
.status-btn:focus {
  background-color: var(--secondary-color);
}

.status-btn.unpublish-btn:hover,
.status-btn.unpublish-btn:focus {
  background-color: var(--dark-yellow-color);
}

.publis-btn::before {
  content: "Published";
}

.unpublish-btn::before {
  content: "Featured";
}

.grid-offers ul {
  gap: 20px;
}

.grid-offers li {
  width: calc(33.333% - 14px);
}

.text-left-offer {
  width: calc(100% - 70px);
  padding-right: 15px;
}

.discount-circle {
  width: 70px;
  height: 70px;
  background-color: var(--secondary-color);
}

/* .discount-circle{
  width: 60px;
  height: 60px;
  background-color: var(--secondary-color);
} */
.off-title {
  line-height: 1;
  font-size: 18px;
}

.off-text {
  line-height: 1;
  font-size: 12px;
  display: block;
  margin-right: 12px;
  font-style: italic;
  font-weight: normal;
}

.grid-offers {
  display: none;
}

.grid-view-filter .list-offers {
  display: none;
}

.grid-view-filter .grid-offers {
  display: block;
}

.offer-info li {
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
}

.dash-icon {
  width: 70px;
  border-radius: 5px;
  background-color: rgba(13, 134, 134, 0.07);
}

.dash-icon span {
  height: 70px;
}

.dash-body {
  padding-left: 25px;
}

.dash-body span {
  font-size: 14px;
}

.dash-card-wrapper {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.05);
}

.focused-offer {
  background-color: var(--secondary-color) !important;
}

.focused-offer * {
  color: var(--bs-white);
}

.focused-offer .dash-icon {
  background-color: var(--bs-white);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
}

.focused-offer.dash-card-wrapper {
  box-shadow: 0 0 12px rgba(13, 134, 134, 0.35);
}

#columnchart_material,
#piechart_3d {
  height: 500px;
  width: 100%;
}

/* Rules and permissions */

.top-tabs-view .nav-item .nav-link {
  color: var(--bs-black);
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 600;
  padding: 12px 25px;
  margin-right: 10px;
}

.top-tabs-view .nav-item .nav-link.active {
  color: var(--bs-white);
  background-color: var(--secondary-color);
}

.modified-accordion .accordion-item:not(:first-child) {
  margin-top: 20px;
  border-radius: var(--bs-border-radius);
  overflow: hidden;
}

.modified-accordion .accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 0.25rem;
}

.modified-accordion .accordion-item:not(:first-of-type) {
  border-top: 1px var(--bs-accordion-border-color) solid;
}

.modified-accordion .accordion-button {
  background-color: var(--border-color);
  text-transform: capitalize;
}

.modified-accordion .accordion-button:focus {
  box-shadow: none;
}

.modified-accordion .accordion-button:not(.collapsed) {
  background-color: var(--secondary-color);
  color: var(--bs-white);
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../images/active-accordion-icon.svg");
}

.checkboxes label:not(:last-child) {
  margin-right: 45px;
}

.edit-atmosphere-menu > ul > li {
  background-color: rgba(0, 0, 0, 0.05);
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
}

.edit-atmosphere-menu > ul > li a,
.menues-edit .add-new-menu a {
  padding: 18px 35px 18px 60px;
  display: flex;
  width: 100%;
  text-decoration: none;
  color: var(--dark-gray);
}

.edit-atmosphere-menu > ul > li > a {
  background-image: url("../images/drag.svg");
  background-repeat: no-repeat;
  background-position: 25px center;
  border-radius: 5px;
}

.edit-atmosphere-menu > ul ul {
  background-color: rgba(0, 0, 0, 0.05);
  position: relative;
  width: calc(100% - 120px);
  margin: 0 auto 15px;
  padding: 0;
}

.edit-atmosphere-menu > ul ul::before {
  position: absolute;
  left: 0;
  height: 100%;
  width: 3px;
  background-color: var(--secondary-color);
  content: "";
}

.edit-atmosphere-menu > ul ul li a {
  padding: 14px 25px;
}

.user-lead-profile {
  width: 45px;
  height: 45px;
}

.user-lead-profile img {
  width: 100%;
  min-height: 45px;
}

.lead-user-detail {
  width: calc(100% - 45px);
  padding-left: 15px;
}

.contact-via i {
  width: 20px;
}

.large-select .check-album {
  width: 31px;
  height: 31px;
}

.large-select .check-album::before {
  background-image: url("../images/check.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.large-select input:checked + .check-album::before {
  background-size: 15px;
  background-color: transparent;
}

.profile-lead {
  width: 65px;
  height: 65px;
  padding: 2px;
  background-color: white;
}

.profile-lead img {
  min-height: 61px;
}

.full-info-view {
  width: calc(100% - 65px);
}

.lead-profile-view {
  background-color: var(--secondary-color);
}

.profile-lead-body li:not(:last-child) {
  margin-bottom: 25px;
}

.showing-data .form-control {
  max-width: 70px;
}

.atmosphere-pagination .page-link,
.atmosphere-pagination button {
  color: var(--text-dark);
  padding: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px !important;
  background-color: var(--gray-border);
}

.atmosphere-pagination .page-item:not(:last-child) {
  margin: 0 8px 0 0;
}

.atmosphere-pagination .page-item.active .page-link,
.atmosphere-pagination .page-item .page-link.active {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  color: var(--bs-white);
}

.album-pagination ul {
  gap: 0;
}

.album-pagination ul li {
  width: auto;
}

.breadcrumb {
  text-transform: capitalize;
}

.breadcrumb a {
  color: var(--secondary-color);
  cursor: pointer;
}

.bottom-devider {
  border-bottom: 5px var(--bs-white) solid;
}

.album-img-delete {
  background-color: rgba(0, 0, 0, 0.75);
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.album-img-delete i {
  font-size: 14px;
}

.add-more-media i {
  font-size: 22px;
}

.created-info span {
  font-size: 12px;
}

.image-detail-points ul {
  padding-left: 20px;
  overflow-x: auto;
  height: 175px;
}

.image-detail-points li {
  margin: 12px 0;
}

bootstrap-tagsinput {
  box-shadow: none;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.bootstrap-tagsinput input {
  padding-left: 15px;
}

.bootstrap-tagsinput input,
.bootstrap-tagsinput input:focus {
  border: 0;
  box-shadow: none;
  outline: 0;
}

.label-info {
  background-color: var(--light-gray);
  color: var(--bs-white);
  padding: 5px 10px;
  border-radius: 3px;
  font-weight: 300;
}

.bootstrap-tagsinput .tag [data-role="remove"] {
  margin-left: 8px;
  cursor: pointer;
  width: 15px;
  display: inline-block;
  height: 15px;
  vertical-align: middle;
  background-image: url(../images/cancel.svg);
  background-repeat: no-repeat;
  background-size: 14px;
}

.press-top-action .btn {
  font-size: 12px;
}

.press-body li {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.press-body li:not(:last-child) {
  margin-bottom: 18px;
}

.press-actions a {
  color: var(--secondary-color);
  margin-left: 3px;
}

.card-footer .trash-move {
  color: var(--secondary-color);
}

.card-footer {
  border-top: 0;
  background-color: rgb(232, 232, 232);
}

.press-link span {
  cursor: pointer;
  width: 28px;
}

.press-link a {
  color: var(--secondary-color);
  display: flex;
  font-size: 13px;
  width: calc(100% - 28px);
  word-break: break-all;
}

.status-select {
  min-width: 130px;
}

.info-release {
  font-size: 15px;
}

.info-release li {
  opacity: 0.8;
}

.info-release li:not(:last-child) {
  margin-right: 30px;
}

.press-large-text {
  margin-top: -120px;
  width: calc(100% - 100px);
  backdrop-filter: blur(7px);
  background-color: rgba(255, 255, 255, 0.9);
}

.press-large-image {
  height: 500px;
}

.press-large-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.uploaded-property-img .property-img {
  width: calc(100% / 9 - 18px);
}

.uploaded-property-img .property-img .upload-img img {
  max-width: 100%;
  border-radius: 5px;
}

.remove-img {
  width: 25px;
  height: 25px;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 0 5px 0 0;
}

.uploaded-property-img {
  gap: 20px;
}

.uploaded-property-img .property-img:not(.uploaded) {
  border: 1px dashed var(--gray-border);
  font-size: 35px;
  color: var(--gray-border);
}

.amenity-view {
  width: calc(100% - 30px);
}

.occupancy-row .or-col {
  max-width: 50px;
}

.bed-add-row {
  width: calc(100% - 80px);
}

.add-more-blocks {
  width: 80px;
}

.add-more-blocks {
  padding-top: 7px;
}

.add-more-blocks .btn-theme {
  padding: 8px 15px;
  font-size: 18px;
}

.check-amenity {
  width: 30px;
}

.yes-input {
  max-width: 50%;
}

.icon-amenity img {
  /* height: 20px; */
  width: 50%;
  object-fit: cover;
}

.icon-amenity {
  width: 40px;
}

.name-amenity {
  width: calc(100% - 40px);
}

/* Login */
.login-wrapper {
  min-height: 100vh;
  background-image: url("../images/atmosphere-login-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.login-wrapper::after {
  content: "";
  opacity: 0.9;
  background-color: var(--side-nav-dark-blue);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.login-wrapper form {
  max-width: 400px;
  background-color: rgba(0, 0, 0, 0.09);
}

.login-wrapper .form-control {
  padding: 14px 20px;
}

.login-logo img {
  width: 125px;
}

.add-more-blocks .btn.theme-btn {
  background-color: var(--secondary-color);
}

.new-unit-add-form > .row:first-child {
  padding-top: 0 !important;
}

.occupancy-view {
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0.4rem;
}

.occupancy-view span {
  min-width: 35px;
  height: 35px;
  background-color: var(--bs-border-color);
}

.occupancy-view .form-control {
  background-position: calc(100% - 35px) center;
}

.select-bed-col {
  width: calc(50% - 48px);
}

.select-bed-col:nth-child(odd) {
  margin-right: 24px;
}

.menues-edit ul::before,
.menues-edit > ul ul::before {
  content: "";
  position: absolute;
  left: 10px;
  top: -15px;
  border-radius: 10px;
  height: calc(100% + 30px);
  width: 4px;
  background-color: #f2f2f2;
  z-index: 0;
}

.menues-edit > ul > li {
  background-color: transparent;
}

.menues-edit li {
  position: relative !important;
}

.menues-edit > ul > li:not(:last-child) {
  margin-bottom: 12px;
}

.menues-edit > ul > li > a,
.menues-edit > ul ul a,
.menues-edit .add-new-menu a {
  padding: 18px 30px 18px 45px;
  background-color: rgb(242, 242, 242);
  text-transform: uppercase;
}

.menues-edit > ul ul {
  margin-top: 10px;
  background-color: transparent;
  list-style: none;
  position: relative;
}

.menues-edit > ul ul li:not(:last-child) {
  margin-bottom: 10px;
}

.menues-edit > ul .edit-sub-menu li:last-child {
  margin-bottom: 0;
}

.menues-edit .add-new-menu a {
  width: auto;
  display: inline-block;
  font-size: 13px;
  background-color: var(--secondary-color);
  padding: 6px 10px;
}

.menues-edit > ul ul::before {
  top: 0;
  height: calc(100% + 17px);
}

.menu-child ~ .add-new-menu {
  margin-bottom: 12px;
}

.menues-edit li {
  position: relative;
}

.add-submenu {
  top: 16px;
  margin-right: 30px;
}

.menues-edit .add-submenu a {
  padding: 5px 8px;
  background-color: rgba(0, 0, 0, 0.08);
  color: #000;
}

.menu-form {
  background-color: rgba(0, 0, 0, 0.035);
  padding: 25px 35px;
  border-radius: 5px;
}

.refreshnow {
  right: 30px;
  top: 51px;
}

.refreshnow i {
  padding: 5px;
}

.menu-submenu-flow ul li a {
  background-image: url("../../assets/images/drag.svg");
  background-repeat: no-repeat;
  background-position: 25px center;
  padding: 18px 30px 18px 45px;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
}

.menu-submenu-flow .accordion-item {
  margin: 15px 0;
  border-radius: 5px;
  border: 1px rgba(0, 0, 0, 0.2) solid;
  overflow: hidden;
}

.menu-submenu-flow .accordion-header .accordion-button {
  background-color: rgba(0, 0, 0, 0.05);
  background-image: url("../images/drag.svg");
  background-repeat: no-repeat;
  background-position: 20px center;
  padding-left: 40px;
  color: var(--bs-black);
}

.menu-submenu-flow .accordion-body {
  padding-top: 0;
  padding-bottom: 0;
}

.check-conditions .check-album {
  width: 24px;
  height: 24px;
}

.check-conditions .check-album::before {
  left: 5px;
  top: 5px;
}

.grid-view-nav {
  gap: 0 24px;
}

.grid-view-nav li {
  width: calc(100% / 4 - 20px);
}

.grid-view-nav .left-nav,
.grid-view-nav .nav-action,
.grid-view-nav .image-nav,
.grid-view-nav .card-title {
  width: 100%;
}

.grid-view-nav .card-title {
  padding: 0;
}

.discover-items fieldset {
  border: 1px #ddd solid;
  padding: 0px 20px 5px;
  border-radius: 5px;
}

.discover-items .field-group {
  margin-top: -12px;
}

.field-group label {
  position: relative;
  background-color: var(--bs-white);
  padding: 0 10px;
}

.thumb-resorts .bed-add-row {
  width: calc(100% - 68px);
}

.social-link .input-group-text {
  min-width: 70px;
  display: flex;
  justify-content: center;
}

.switch-back a {
  background-color: var(--secondary-color);
  padding: 14px 0;
}

.switch-website .form-check-input:checked {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.toggle-website {
  background-color: rgba(0, 0, 0, 0.05);
  color: var(--bs-black);
  border: rgba(13, 134, 134, 0.15) solid;
}

.brand-select-card {
  max-width: 650px;
}

.brand-select {
  width: calc(100% - 150px);
  padding-right: 20px;
}

.add-more-brand {
  width: 150px;
  padding-bottom: 1.5px;
}

.item-discover {
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  padding: 20px 20px 0;
  margin-bottom: 18px;
}

.select-bed-col {
  width: calc(50% - 48px);
}

.select-bed-col:nth-child(odd) {
  margin-right: 24px;
}

.input-number input[type="number"] {
  -webkit-appearance: textfield;
  appearance: textfield;
  -moz-appearance: textfield;
}

.press-actions .press-date-wrap {
  position: relative;
}

/* .press-actions .press-date-wrap input[type="date"]{
  background-image: url("../images/edit.svg");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: right center;
} */
.press-actions .press-date-wrap input:focus[type="date"] {
  outline: 0;
}

.press-actions .press-date-wrap input::-webkit-calendar-picker-indicator {
  filter: contrast(117%);
}

/* .press-actions .press-date-wrap::before {
  content: "";
  width: 22px;
  height: 100%;
  background-color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
} */

/* 27 Dec */
.btn.login-btn,
.btn.login-btn:active,
.btn.login-btn:visited,
.btn.login-btn:focus,
.btn.login-btn:focus-visible {
  height: 55px;
  background-color: var(--black-shade-1) !important;
  border-color: var(--black-shade-1) !important;
}

.permission-view .roles-view {
  background-color: rgba(0, 0, 0, 0.03);
  padding: 5px 15px;
  margin: 0 0 24px;
}

.main-navigation {
  padding: 12px 0;
}

.permission-view .roles-view ol li:not(:last-child) {
  border-bottom: 1px #ddd solid;
}

/* welcome image */

.home-logo img {
  width: 90px;
}

/* .page-header.close_icon {
  background-size: 50px;
  background-image: url(../images/dashboard-menu.svg);
  background-repeat: no-repeat;
}

.page-header.close_icon a img{
  display: none;
} */

#react-select-3-input {
  padding: 9px 25px !important;
}

.left-header .back-arrow img {
  width: 20px;
}

.upload-loader {
  width: 88.28px;
}

.loader-wrapper {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dining-table-image img {
  width: 100px;
}

.add-remove-btn {
  margin-top: 2.5rem;
}

form .css-13cymwt-control {
  height: 58px;
  border-color: var(--bs-border-color) !important;
  border-radius: var(--bs-border-radius);
  box-shadow: none !important;
  overflow: auto;
}

form .css-t3ipsp-control {
  box-shadow: none;
  height: 58px;
  box-shadow: none;
  overflow: auto;
}

form .css-13cymwt-control:focus {
  box-shadow: none !important;
}

form .css-t3ipsp-control:hover,
form .css-t3ipsp-control:focus {
  border-color: #86b7fe !important;
  box-shadow: none !important;
}

.specification-dropdown .booking-form-input {
  width: calc(25% - 57px);
}

.booking-form-input + .booking-form-input {
  margin-top: 15px;
}

.booking-form-input + .booking-form-input label {
  display: none;
}

.specification-dropdown .booking-form-input .form-control {
  background-color: var(--bs-white);
  border: 1px var(--bs-border-color) solid;
  color: var(--bs-body-color);
  height: 58px;
  border-radius: var(--bs-border-radius);
  text-align: left;
}

.specification-dropdown .booking-form-input .form-control::after {
  position: absolute;
  right: 20px;
  top: 27px;
}

.specification-dropdown .booking-form-input .form-control:focus {
  background-color: transparent;
  border-color: #86b7fe;
  color: var(--bs-body-color);
}

.specification-dropdown .guest-dropdown {
  width: 100%;
}

.specification-dropdown .guests-input__value {
  text-align: center;
}

.guest-dropdown,
.guests-input_options,
.guest-card,
.specification-dropdown .dropdown-menu {
  border-color: var(--bs-border-color) !important;
}

.col-xxl-12:empty {
  display: none !important;
}

.btn.sub-brand-select,
.btn.sub-brand-select:hover,
.btn.sub-brand-select:visited,
.btn.sub-brand-select:active,
.btn.sub-brand-select:focus {
  color: var(--bs-body-color) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  box-shadow: none;
}

.btn.sub-brand-select::after {
  content: none;
}

.sub-brand-contain .dropdown-menu {
  min-width: 100%;
}

.sub-brand-contain .dropdown-item {
  padding: 10px 20px;
}

.bottom-text p {
  max-width: 420px;
  margin: auto;
}

.bottom-text p span {
  opacity: 0.87;
}

.tabledata .table thead tr th:first-child,
.tabledata .table tbody tr td:first-child {
  width: 65px;
}

.mandatory {
  color: red;
}

.modal-open .modal {
  backdrop-filter: blur(5px);
}

.modal-open .modal .modal {
  backdrop-filter: blur(0);
}

form .publish-resort-select-box .css-13cymwt-control,
form .publish-resort-select-box .css-t3ipsp-control {
  height: auto;
  min-height: 58px;
}

form .publish-resort-select-box #react-select-3-input {
  padding: 3px 5px !important;
}

.bed-count-col {
  padding: 14.5px 10px;
  border-radius: 5px;
}

.bed-count-col .guests-input__ctrl {
  border: 0;
}

.uploaded-property-img .upload-img {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 83.6667px;
}

.specification-dropdown .booking-form-input.error {
  border-color: transparent;
}

.specification-dropdown .booking-form-input.error .form-control {
  border-color: red;
}

.drag-drop-optional-table.table thead tr th:first-child,
.drag-drop-optional-table.table tbody tr td:first-child {
  width: 35px;
  text-align: center;
  cursor: grab;
}

.drag-drop-optional-table.table thead tr th:nth-child(2),
.drag-drop-optional-table.table tbody tr td:nth-child(2) {
  width: 65px;
}

.modal-title {
  word-break: break-all;
}

.remove-image-button {
  width: 30px;
  height: 30px;
  border: 0;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 0 0 0 7px;
}

.remove-image-button svg {
  color: white;
}

.modal-open .modal {
  display: none !important;
}

.modal-open .modal:last-child {
  display: block !important;
}

.save-btn .btn-theme {
  width: 86px;
}

.uploaded-img-view {
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploaded-property-img .uploaded-img-view {
  max-width: 200px;
  width: auto !important;
}

.uploaded-property-img .upload-img > div {
  height: 100%;
  width: 100%;
}

.media-contains {
  height: 450px;
  overflow-y: auto;
}

.upload-media-album .upload-icon {
  max-width: 182px;
}

.res {
  height: 40px;
  /* width: 124px; */
}

.login-wrapper .login-fields input[type="password"],
.login-wrapper .login-fields #Password {
  padding-right: 45px;
}

.dropdown-to-select {
  background-color: transparent !important;
  color: var(--bs-body-color) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

.dropdown-to-select::after {
  content: none !important;
}

.dropdown-to-select:hover,
.dropdown-to-select:focus {
  color: var(--bs-body-color) !important;
}

.collateral-select .dropdown-menu {
  width: 100%;
}

.collateral-select .dropdown-menu .dropdown-item {
  display: flex;
  padding: 15px;
}

.collateral-select .dropdown-menu .dropdown-item:not(:last-child) {
  border-bottom: var(--bs-border-width) solid var(--bs-border-color);
}

.select-text {
  width: calc(100% - 50px);
  white-space: wrap;
}

.collateral-select .dropdown-menu .dropdown-item:active,
.sub-brand-contain .dropdown-menu .dropdown-item:active {
  background-color: initial;
  color: initial;
}

.drop-item-name {
  width: calc(100% - 70px);
  display: flex;
  white-space: pre-wrap;
  word-break: break-word;
}

.colletral-drop-item {
  width: calc(100% - 15px);
}

.forgot-logo img {
  max-width: 90px;
}

.uploaded-file-view {
  width: 70px;
}

.removebtn {
  width: 18px;
  height: 18px;
  padding: 0;
  line-height: 0;
  border-radius: 0;
  font-size: 13px;
}

/* Analystic Dashboard */
.analystic-widgets h4 {
  color: var(--dark-gray);
}

.analystic-widgets img {
  max-width: 100%;
}

.analystic-widgets .card {
  border-radius: 20px;
  padding: 20px 25px;
  border: 0;
}

.analystic-widgets .card-header h6 {
  color: var(--dark-gray);
  font-size: 15px;
}

.analystic-widgets .full-view .h6 {
  font-size: 18px;
}

.card-top-head {
  font-size: 14px;
  color: var(--dark-gray);
  min-height: 45px;
}

.value-stats svg {
  font-size: 20px;
}

.increase-value {
  color: var(--increase-value);
  font-weight: 600;
}

.decrease-value {
  color: var(--decrease-value);
  font-weight: 600;
}

.anylystic-card-value h2 {
  color: var(--dark-gray);
  font-weight: 600;
}

.filter-btns-group .btn,
.filter-btns-group .btn.country-button {
  border-color: var(--btn-border);
  color: var(--btn-border);
  font-size: 14px;
  border-radius: 4px;
  padding: 10px 23px;
  line-height: 1;
}

.filter-btns-group .btn:hover,
.filter-btns-group .btn.active,
.btn.export-btn:hover,
.btn.export-btn:focus,
.filter-btns-group .btn.country-button.active,
.filter-btns-group .btn.country-button:hover {
  color: var(--bs-white);
  background-color: var(--btn-hover);
  border-color: var(--btn-hover);
}

.filter-btns-group .btn.active {
  box-shadow: 0 6px 10px 0 rgba(122, 127, 175, 0.3);
}

.filter-btns-group::after {
  content: "";
  position: absolute;
  right: 0;
  top: 6px;
  height: 24px;
  width: 1px;
  background-color: var(--btn-border);
}

.custom-date-filters input {
  border-color: var(--btn-border);
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.571;
  transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.custom-filter form {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(500px);
  opacity: 0;
  transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.filter-toggle form {
  transform: translateX(0px);
  position: relative;
  opacity: 1;
}

.filter-toggle > button {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.custom-filter::after {
  content: none;
}

table tr td {
  vertical-align: middle;
}

.default-bg-table table thead tr th {
  border-bottom: 8px var(--bs-white) solid;
  font-size: 14px;
  color: #6f707c;
  font-weight: normal;
}

.default-bg-table table tbody tr td,
.default-bg-table table thead tr th {
  background-color: var(--table-td-bg);
  padding: 10px 20px;
}

.perfoming-countries.default-bg-table table tbody tr td,
.perfoming-countries.default-bg-table table thead tr th {
  background-color: var(--table-td-bg);
  padding: 7.25px 20px;
}

.default-bg-table table tbody tr td:first-child,
.default-bg-table table thead tr th:first-child {
  border-radius: 10px 0 0 10px;
}

.default-bg-table table tbody tr td:last-child,
.default-bg-table table thead tr th:last-child {
  border-radius: 0 10px 10px 0;
  font-weight: 600;
}

.perfoming-countries table tbody tr td:last-child {
  font-size: 20px;
  font-weight: 500;
  color: var(--bs-black);
}

.perfoming-countries table tbody tr td:last-child svg {
  display: none;
}

.bottom-border {
  border-bottom: 11px solid var(--bs-white);
}

.btn.export-btn {
  font-size: 12px;
  color: var(--btn-hover);
  background-color: #f4f5fd;
  border: 0;
}

.btn.export-btn svg {
  font-size: 18px;
}

.geographical-distribution {
  margin: 35px 0;
}

.v-border {
  height: 100%;
  width: 1px;
  background-color: #d5d6e1;
  min-height: 255px;
}

.default-data-tab-btns .rts___tab {
  border-color: #d5d6e1;
  border-width: 1px;
  color: #6f707c;
  font-size: 14px;
  padding: 6.5px 26px;
}

.default-data-tab-btns .rts___tab.active {
  background-color: var(--btn-hover);
  color: var(--bs-white);
  border-color: var(--btn-hover);
  box-shadow: none;
}

.default-data-tab-btns .rts___tab:hover {
  background-color: var(--table-td-bg);
  color: var(--btn-hover);
}

.tabs-header .rts___tabs {
  max-width: 275px;
}

.default-data-tab-btns .rts___nav___btn___container .rts___nav___btn {
  border-color: var(--dark-gray);
  border-width: 1px;
  width: 36px;
  height: 36px;
  padding: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../images/prev.svg");
}

.default-data-tab-btns
  .rts___nav___btn___container:last-child
  .rts___nav___btn {
  background-image: url("../images/next.svg");
}

.default-data-tab-btns .rts___nav___btn___container .rts___nav___btn:hover,
.default-data-tab-btns .rts___nav___btn___container .rts___nav___btn:focus {
  background-color: var(--table-td-bg);
}

.default-data-tab-btns .rts___nav___btn___container .rts___nav___btn svg {
  display: none;
}

.overview-tabs .tabs-header {
  margin-top: -35px;
}

.smalltxt {
  font-size: 14px;
}

.session-search-table table tr td,
.session-search-table table tr td:last-child {
  font-size: 16px;
  font-weight: 500 !important;
}

.small-title {
  font-size: 13px;
  color: var(--dark-gray);
  font-weight: 600;
}

.google-reviews a {
  color: var(--review-star);
  font-size: 24px;
}

.upgrade-service {
  background: rgb(138, 147, 227);
  background: linear-gradient(
    143deg,
    rgba(138, 147, 227, 1) 0%,
    rgba(164, 108, 255, 1) 100%
  );
}

.upgrade-service h5 {
  max-width: 200px;
  line-height: 1.6;
}

.upgrade-service p {
  font-size: 14px;
}

.upgrade-action .btn {
  line-height: 1;
  font-size: 12px;
  padding: 12px 15px;
}

.upgrade-action .btn:hover,
.upgrade-action .btn:focus {
  background-color: rgba(0, 0, 0, 0.3) !important;
  color: var(--bs-white) !important;
}

.booking-card {
  background-color: #ffb199 !important;
}

.enquiry-card {
  background-color: #a6ebc2 !important;
}

.left-card-text span {
  color: var(--dark-gray);
  display: block;
  font-size: 14px;
  font-weight: normal;
}

.card-large-value .h2 {
  color: var(--dark-gray);
}

.analystic-widgets .sidebar-cols {
  margin-top: 25px;
}

.full-view {
  position: relative;
  top: -4px;
  right: -10px;
}

.full-view a,
.back-to-dashboard a {
  color: var(--dark-gray);
}

.site-health {
  margin-bottom: 14px;
}

.dashboard-action-strip {
  border-radius: 15px;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.03);
}

/* .dashboard-action-strip::after{
  content: "";
  position: absolute;
  left: -40px;
  top: 0;
  width: calc(100% + 80px);
  height: 1px;
  background-color: #d5d6e1;
} */

.header-right-actions .btn {
  /* background-color: var(--btn-hover); */
  line-height: 1;
  border-radius: 4px;
  padding-top: 8.75px;
  padding-bottom: 8.75px;
}

.header-right-actions .btn:hover {
  background-color: var(--dark-gray);
}

.review-user span {
  width: 35px;
  height: 35px;
}

.review-user-info {
  width: calc(100% - 35px);
}

.review-time small {
  font-size: 13px;
}

.review-user-info .user-name,
.review-time {
  line-height: 1.43;
}

.google-reviews-list li:last-child {
  border-bottom: 0 !important;
}

.review-content p {
  font-size: 14px;
}

.google-review-modal .ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.3) !important;
  z-index: 999;
}

.google-review-modal .ReactModal__Content--after-open {
  border: 0 !important;
}

.google-review-modal .review-content p button {
  display: none;
}

.google-review-modal .modal-body li {
  border-bottom: 0 !important;
  padding: 0 !important;
}

.google-review-modal .review-content {
  margin-top: 10px;
}

/* .trfc-ratio {
  aspect-ratio: 1.61/1;
}

.tp-prmnc {
  aspect-ratio: 1.04/1;
}

.geo-ratio {
  aspect-ratio: 2.14/1;
}

.dmo-graphic-ratio {
  aspect-ratio: 3.13/1;
}

.sessn-ratio {
  aspect-ratio: 1.41/1;
}

.sesson-reasrch-ratio {
  aspect-ratio: 2.81/1;
}

.sesson-nw-ratio {
  aspect-ratio: 1.37/1;
}

.dmo-graphics-ratio {
  aspect-ratio: 2.88/1;
} */

.webiste-engagement-stats li:not(:last-child) {
  margin-bottom: 23px;
  padding-bottom: 38px;
}

.engagement-card.analystic-table {
  overflow-x: hidden;
}

.engagement-card .card-header,
.webiste-engagement-stats {
  padding-left: 25px;
  padding-right: 25px;
}

/* .webiste-engagement-stats{
  overflow: auto;
  height: 736px;
} */
.dashboard-overlay-placeholder {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(10px);
  height: 100vh;
  left: 220px;
  width: calc(100% - 220px);
  top: 88px;
}

.stat-icon-side {
  width: 70px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.035);
}

.left-stats-engage {
  width: calc(100% - 70px);
}

.staticon {
  width: 35px;
  height: 35px;
  font-size: 20px;
}

.icnspn {
  font-size: 14px;
}

.stattext {
  width: calc(100% - 35px);
  font-size: 14.5px;
}

.bg-enqr {
  background-color: #d2f4ea;
}

.bg-nwsltr {
  background-color: #f8d7da;
}

.bg-offr {
  background-color: #cfe2ff;
}

.bg-vlla {
  background-color: #fff3cd;
}

.webiste-engagement-stats {
  padding-left: 18px;
  padding-right: 18px;
}

.engagement-stat-card-header {
  padding-left: 18px !important;
}

.note-card {
  background: rgb(138, 147, 227);
  background: linear-gradient(
    143deg,
    rgba(138, 147, 227, 1) 0%,
    rgba(164, 108, 255, 1) 100%
  );
}

.note-card .card-header h6 {
  font-size: 16.5px;
  line-height: 1.7;
}

/* .treffic-overview table tbody tr td:first-child .perfom-texsmall {
  width: 100px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
} */
.cntry-tab-btns::after {
  content: none;
}

.quil-description .ql-toolbar.ql-snow {
  border: 1px solid #dee2e6;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.quil-description .ql-container.ql-snow {
  border: 1px solid #dee2e6;
  border-radius: 6px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.cstm-select .css-1kp55yj-control,
.cstm-select .css-lgeszn-control {
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  min-height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: none;
}

.cstm-select .css-1kp55yj-control:hover,
.cstm-select .css-lgeszn-control:hover,
.cstm-select .css-1kp55yj-control:focus,
.cstm-select .css-lgeszn-control:focus {
  border-color: #86b7fe;
}

.cstm-select .css-1kp55yj-control .css-1fdsijx-ValueContainer,
.cstm-select .css-lgeszn-control .css-1fdsijx-ValueContainer,
.cstm-select .css-lgeszn-control .css-hlgwow,
.cstm-select .css-1kp55yj-control .css-hlgwow {
  display: flex;
  height: 46px;
  padding: 0 9px;
}

.cstm-select .css-1kp55yj-control .css-1fdsijx-ValueContainer *,
.cstm-select .css-lgeszn-control .css-1fdsijx-ValueContainer *,
.cstm-select .css-lgeszn-control .css-hlgwow *,
.cstm-select .css-1kp55yj-control .css-hlgwow * {
  font-size: 16px;
}

.cstm-select .css-1h2eidq-indicatorContainer .css-tj5bde-Svg {
  color: #333 !important;
}

.cstm-select .css-pmpsbk-menu {
  padding: 5px 0 5px;
  border-radius: 0 0 5px 5px;
}

.cstm-select .edit-btn,
.cstm-select .delete-btn {
  cursor: pointer;
}

.header-right-actions .share-btn:hover,
.header-right-actions .share-btn:focus {
  background-color: var(--btn-hover);
}

.name-amenity a {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  display: inline-block;
}

.amenity-view {
  width: calc(100% - 80px);
}

.uat-notify-strip {
  margin-left: -40px;
  position: relative;
  width: calc(100% + 80px);
  top: -18px;
}
.downarrow {
  font-size: 14px;
}
.talign thead tr th:first-child,
.talign tbody tr td:first-child {
  text-align: center;
}

.trshicon{
  width: 19px;
  height: 19px;
  margin: 0 1px;
}
.modal-backdrop.release-backdrop {
  background: transparent !important;
  opacity: 1;
}
.release-note-modal{
  backdrop-filter: none !important;
}
.custom-modal-backdrop .release-note-modal .modal-dialog{
  margin-left: 15px;
  max-width: 390px;
}
.custom-modal-backdrop .release-note-modal .modal-content{
  border-bottom: 0;
}
.custom-modal-backdrop .release-note-modal .modal-content::before{
  content: "";
  position: absolute;
  left: 105px;
  bottom: -12px;
  width: 15px;
  height: 15px; 
  border-left: 18px solid transparent;
  border-right: 12px solid transparent;
  border-top: 18px solid white;
}
.release-note-modal.modal{
  align-items: flex-end !important;
  padding-bottom: 30px;
}
.custom-modal-backdrop .release-note-modal.modal{
  display: flex !important;
}
.custom-scroll .kpmZQS{
  background-color: rgba(0, 0, 0, 0.4);
}
.rcs-custom-scroll .release-text{
  padding-right: 15px;
}

.release-text *{
  opacity: 0.8;
}